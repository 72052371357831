import { Link, withPrefix } from "gatsby"
import React from "react"
import SasaViolet from "@svg/sasa-violet"
import PIT from "@svg/pit"
import { pl, en } from "@content/header.yaml"

const HeaderSubpage = ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <nav className="header_nav opacity">
      <div className="expanded row">
        <div className="large-4 medium-5 small-2 columns">
          <Link
            className="logo "
            to={isEn ? '/en': '/'}
            title="SaSa | Crowdfunding medyczny - SaSa | Crowdfunding medyczny"
          >
            <SasaViolet />
          </Link>
        </div>

        <div className="large-8 medium-7 small-10 columns text-right">
          <div className="links hide-for-large">
            {!isEn && (
              <a
                href="http://bit.ly/2E8LP70"
                className="help one-percent-link"
                rel="dofollow"
                target="_blank"
              >
                <PIT />
                KRS 0000 552525
              </a>
            )}
            <a
              data-borderpopup="borderpopup-pomoc"
              className="open-borderpopup help"
            >
              <i className="fa fa-heart"></i>
            </a>
          </div>
          <div className="links show-for-large">
            {!isEn && (
              <a href="http://bit.ly/2E8LP70" className="help one-percent-link">
                <PIT />
                KRS 0000 552525
              </a>
            )}

            <a
              href=""
              data-borderpopup="borderpopup-pomoc"
              className="open-borderpopup help"
            >
              <img
                src={withPrefix(`images/header/heart.svg`)}
                alt="crowdfunding medyczny"
                className="fa"
              />
              {translation.howcanyouhelp}
            </a>
            {isEn && <Link to="/">Polski</Link>}
            {!isEn && <Link to="/en">English</Link>}
          </div>
          <div className="mobilemenu_btn_content mobilemenu_btn_open">
            <div className="mobilemenu_btn">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default HeaderSubpage
