import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "@layout/layout"
import { pl, en } from "@content/pages/testujemymedykow.yaml"
import HeaderSubpage from "@layout/header-subpage"
import SEO from "@layout/seo"

export default ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <Layout isEn={isEn}>
      <SEO
        isEn={isEn}
        title={translation.metaTitle}
        description={translation.metaDescription}
      />
      <HeaderSubpage isEn={isEn}/>
      <section className="section wrapper">
        <section
          className="news-text padding wow fadeInUp"
          data-wow-duration="1.5s"
          style={{
            visibility: "hidden",
          }}
        >
          <div className="row">
            <div className="large-8 large-centered medium-12 small-12 columns">
              <h1 className="text-center">#TestujemyMedyków</h1>
            </div>
          </div>
        </section>
        <section
          className="news-text padding wow fadeInUp"
          data-wow-duration="1.5s"
          style={{
            visibility: "hidden",
          }}
        >
          <div className="row">
            <div className="large-8 large-centered medium-12 small-12 columns">
              <p>
                “Mamy prosty przekaz: testujcie, testujcie, testujcie” –
                powiedział szef Światowej Organizacji Zdrowia (WHO). Testy
                dostępne dla wszystkich lekarzy, pielęgniarek i ratowników
                medycznych są z uwagi na obecną sytuację epidemiologiczną
                szczególnie ważne.{" "}
              </p>
              <p>
                <strong>Dlaczego? </strong>
                <br />
                Jako osoby pracujące na pierwszej linii frontu medycy codziennie
                mają kontakt z wieloma chorymi, którzy niekoniecznie zgłaszają
                się do przychodni i szpitali z objawami infekcji koronawirusa.
                Aby ograniczyć rozprzestrzenianie się wirusa bardzo ważne jest,
                aby możliwie jak najszybciej wyłapywać wszystkie zarażone osoby.
                W przypadku medyków jest to szczególnie istotne:
                <br />
                <a href={withPrefix("images/testujemymedykow/testujemy1.jpg")}>
                  <img
                    src={withPrefix("images/testujemymedykow/testujemy1.jpg")}
                    alt=""
                    width={1024}
                    height={1024}
                    className="alignleft size-large wp-image-1433"
                  />
                </a>
              </p>
              <p>
                <strong>
                  Naszym celem jest umożliwienie jak największej grupie medyków
                  wykonania bezpłatnych badań.{" "}
                </strong>
              </p>
              <p>
                <strong>Obecna sytuacja:</strong>
                <br />
                Większość lekarzy, ratowników i pielęgniarek nie ma możliwości
                wykonania testów, jeżeli nie zalicza się do grupy osób z
                bezpośredniego kontaktu z osobą zakażoną. Inne akcje dotyczące
                darmowych badań dla medyków dotyczą głównie dużych miast, jednak
                nawet tam możliwości wykonania badań są zdecydowanie
                niewystarczające.{" "}
              </p>
              <p>
                <strong>Pandemia:</strong>
              </p>
              <p>
                Pomimo znoszenia kolejnych obostrzeń, walka z epidemią w naszym
                kraju trwa nadal. Każdego dnia w statystykach pojawiają się
                kolejne setki zakażonych, którzy spotykają się z pracownikami
                systemu ochrony zdrowia w przychodniach i szpitalach.{" "}
              </p>
              <p>
                <strong>Nasze plany</strong>
              </p>
              <p>
                We współpracy z laboratoriami na terenie całego kraju chcemy
                umożliwić medykom możliwość wykonania badań na obecność
                koronawirusa. W tym celu rozpoczęliśmy zbiórkę pieniędzy, która
                ma nam pomóc sfinansować badania.{" "}
              </p>
              <p>
                <a
                  href="https://zrzutka.pl/9mpsxk"
                  rel="noopener"
                  target="_blank"
                >
                  https://zrzutka.pl/9mpsxk
                </a>
              </p>
              <p>
                Medycy będą mogli wykonać badania bez dodatkowych skierowań i
                bez zbędnych formalności. Łatwa procedura, brak dodatkowych
                warunków i szybka obsługa medyków – zrobimy wszystko, aby medycy
                w tych ciężkich chwilach nie musieli dodatkowo martwić się o
                wykonywanie badań. Obecnie do akcji dołączyło niemal 300
                placówek na terenie całej Polski, które zrezygnują z marży na
                rzecz akcji.{" "}
              </p>
              <p>
                <strong>Dostępne badania:</strong>
                <br />
                Firma Diagnostyka zaproponowała nam następujący protokół badań:
              </p>
              <p>
                <a href={withPrefix("images/testujemymedykow/testujemy2.jpg")}>
                  <img
                    src={withPrefix("images/testujemymedykow/testujemy2.jpg")}
                    alt=""
                    className="alignleft size-large"
                  />
                </a>
                <br />
                Stawiamy na badania serologiczne, ponieważ są dostępne w
                Punktach Pobrań na terenie całej Polski. W zależności od potrzeb
                możecie wybrać także diagnostykę genetyczną metodą RT PCR. W obu
                przypadkach wyniki badań będziecie mogli odebrać online.
              </p>
              <p>
                <strong>
                  Napisaliśmy artykuł, który podsumowuje dostępne informacje na
                  temat badań związanych z koronawirusem. Jeżeli jesteś
                  medykiem, artykuł możesz przeczytać tutaj:{" "}
                  <a
                    href="https://bml.pl/artykuly/medycyna/testy-w-diagnostyce-sars-cov-2-co-powinniscie-o-nich-wiedziec#"
                    rel="noopener"
                    target="_blank"
                  >
                    https://bml.pl/artykuly/medycyna/testy-w-diagnostyce-sars-cov-2-co-powinniscie-o-nich-wiedziec#
                  </a>
                </strong>
              </p>
              <p>
                <a href={withPrefix("images/testujemymedykow/testujemy3.jpg")}>
                  <img
                    src={withPrefix("images/testujemymedykow/testujemy3.jpg")}
                    alt=""
                    className="alignleft size-large"
                  />
                </a>
                <br />
                <strong>Co ze środkami ochrony osobistej?</strong>
              </p>
              <p>
                Dopuszczamy możliwość przeznaczenia części kwoty na Środki
                Ochrony Indywidualne dla placówek medycznych, jeżeli sytuacja w
                tym zakresie będzie się pogarszać. Testowani medycy muszą wracać
                do pracy w szpitalach i przychodniach w warunkach pełnego
                zabezpieczenia.{" "}
              </p>
              <p>
                W ramach takich zakupów planujemy zaopatrzenie szpitali i
                przychodni w następujący sprzęt:
                <br />
                – indywidualne zestawy ochrony biologicznej IZOB 001
                <br />
                – półmaski filtrujące klasa FFP2/FFP3
                <br />
                – maseczki chirurgiczne trójwarstwowe
                <br />
                – gogle ochronne
                <br />– przyłbice
              </p>
              <p>
                <strong>
                  Jeżeli jesteś medykiem na badania możesz zapisać się tutaj –{" "}
                  <a
                    href="https://bml.pl/testujemy-medykow#"
                    rel="noopener"
                    target="_blank"
                  >
                    https://bml.pl/testujemy-medykow#
                  </a>
                  /
                </strong>
              </p>
              <p>
                Testy przeznaczone dla medyków są naszym zdaniem kluczowym
                elementem dla ograniczenia rozprzestrzeniania się wirusa, a
                także funkcjonowania sytemu ochrony zdrowia.{" "}
              </p>
              <p>
                <strong>1%</strong>
                <br />
                Kryzys w ochronie zdrowia nie zakończy się z pewnością w
                kolejnych tygodniach. Jeżeli jeszcze nie rozliczyliście swojego
                PIT, zachęcamy do przekazania 1% na naszą Fundację – całą kwotę
                przekażemy na wsparcie placówek ochrony zdrowia. Jak zwykle
                przyświeca nam idea mądrej pomocy – kontaktujemy się
                bezpośrednio z personelem i odpowiadamy na ich potrzeby.{" "}
                <img
                  draggable="false"
                  className="emoji"
                  alt="🤝"
                  src="https://s.w.org/images/core/emoji/12.0.0-1/svg/1f91d.svg"
                />
                <br />
                Zachęcamy do wsparcia naszej inicjatywy!
              </p>
              <p>
                <strong>
                  Możesz to zrobić na kilka sposobów:
                  <br />
                  <img
                    draggable="false"
                    className="emoji"
                    alt="➡️"
                    src="https://s.w.org/images/core/emoji/12.0.0-1/svg/27a1.svg"
                  />
                  Przekaż swój 1% podatku wpisując w deklaracje podatkową nasz
                  KRS: 0000552525 – przypominamy, że rozliczenia z Urzędem
                  Skarbowym można dokonać do 31 maja{" "}
                  <img
                    draggable="false"
                    className="emoji"
                    alt="❗️"
                    src="https://s.w.org/images/core/emoji/12.0.0-1/svg/2757.svg"
                  />
                  <br />
                  <img
                    draggable="false"
                    className="emoji"
                    alt="➡️"
                    src="https://s.w.org/images/core/emoji/12.0.0-1/svg/27a1.svg"
                  />
                  Możesz skorzystać także z naszego programu do rozliczeń –{" "}
                  <a
                    href="http://bit.ly/ROZLICZ-PIT-SASA"
                    rel="noopener"
                    target="_blank"
                  >
                    http://bit.ly/ROZLICZ-PIT-SASA
                  </a>
                </strong>
              </p>
              <strong></strong>
              <p>
                <strong>
                  Pamiętaj, że naszą akcję możesz wesprzeć także, przekazując
                  pieniądze bezpośrednio na nasze konto z dopiskiem “Testujemy
                  medyków”.
                  <br />
                  Przewidzieliśmy także specjalne propozycje dla firm, które
                  zdecydują się wesprzeć naszą akcję. Jeżeli interesuje Cię ten
                  rodzaj współpracy zapraszamy do kontaktu na
                  marcin.krajmas@gmail.com
                </strong>
              </p>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  )
}
